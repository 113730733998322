import { isUnicredit } from "@app/lib/tenants/isUnicredit"
import { getValuationSessionId } from "@app/lib/tracking"
import { Address } from "@app/types/address"
import { Contact } from "@app/types/contact"
import { Locale } from "@app/types/locale"
import {
  Answers,
  EnergyClass,
  Floor,
  HeatingSystem,
  ResidentialType,
  StateAtDeed,
  StateOfMaintenance,
  ValuationReason,
  ValuationReasonReasonNotToSell,
  ValuationReasonSellingProcessStatus,
} from "@app/types/survey"
import * as Sentry from "@sentry/browser"

const IS_HEYFLOW_ENABLED: boolean = process.env.NEXT_PUBLIC_HEYFLOW_ENABLED === "true"
const HEYFLOW_PUBLIC_MODE_ENABLED = process.env.NEXT_PUBLIC_HEYFLOW_PUBLIC_MODE_ENABLED === "true"
export const HEYFLOW_BASE_URL = process.env.NEXT_PUBLIC_HEYFLOW_BASE_URL || `https://heyflow.id/casavo-test`

export const HEYFLOW_VALUATION_SOURCE = "Heyflow"

export const HEYFLOW_ABTEST_COOKIE_NAME = "heyflow-test-002"
// 50% probability to be in "test" group (i.e. to be redirected to Heyflow)
const TEST_GROUP_PROBABILITY = 0.5

const DEFAULT_RESIDENTIAL_TYPE = "1"
const DEFAULT_VALUATION_REASON = "INTERESTED_IN_SELLING"
const DEFAULT_VALUATION_REASON_SELLING_PROCESS_STATUS = "NOT_STARTED"
const DEFAULT_VALUATION_REASON_REASON_NOT_TO_SELL = "NOT_PLANNED_YET"

export class HeyflowMappingError extends Error {
  constructor(message: string) {
    super(message)
    this.name = "HeyflowMappingError"
  }
}

/**
 * Used to redirect the user to Heyflow, pushing the address information as parameters on query string.
 *
 * Valuation session ID is provided too (for analytics reasons).
 *
 * WARNING we assume the address is complete and previously checked by flow itself
 *
 * @param {Address} address - The address object containing the required information.
 * @param page - The heyflow page to jump to
 * @returns {void}
 */
export const goToHeyFlow = (address: Address, page?: string): void => {
  const paramsForHeyFlow = {
    // address info that heyflow will have to send back after submit
    city: address.city,
    latitude: address.coordinates.latitude?.toString() || "",
    longitude: address.coordinates.longitude?.toString() || "",
    country: address.country,
    code_country: address.countryCode,
    street: address.street,
    number: address.number,
    zip_code: address.zipCode,
    province: address.province || "", // i.e. Milano
    prov_initials: address.provinceInitials || "", // i.e. MI

    // this will be used on heyflow for analytics tracking reasons
    valuation_session_id: getValuationSessionId() || "",
  }
  const addressQueryString = new URLSearchParams(paramsForHeyFlow).toString()
  // using assign() to avoid losing the browser history

  if (page) {
    window.location.assign(`${HEYFLOW_BASE_URL}?${addressQueryString}#${page}`)
  } else {
    window.location.assign(`${HEYFLOW_BASE_URL}?${addressQueryString}`)
  }
}

/**
 * Determines whether the user should be redirected to the HeyFlow.
 *
 * The user is redirected if
 *  * heyflow redirection is enabled by env var NEXT_PUBLIC_HEYFLOW_ENABLED
 *  * the lang is "it"
 *  * the user is in the heyflow test group
 *
 * @param {Locale} lang - The language of the user.
 * @returns {boolean} - True if the user should be redirected to the HeyFlow, false otherwise.
 */
export const shouldGoToHeyFlow = (lang: Locale): Boolean => {
  if (isUnicredit()) {
    return false
  }

  return IS_HEYFLOW_ENABLED && lang === "it" && isInHeyflowTestGroup()
}

/**
 * Checks whether the user is in the Heyflow test group.
 *
 * Check is performed reading a cookie. If the cookie is not present,
 * the cookie is added and the being part of test group is 50~50
 *
 * @returns {Boolean} True if the user is in the test group, false otherwise.
 */
const isInHeyflowTestGroup = (): Boolean => {
  let cookieValue = getCookieValue(HEYFLOW_ABTEST_COOKIE_NAME)

  if (HEYFLOW_PUBLIC_MODE_ENABLED && cookieValue === undefined) {
    cookieValue = Math.random() < TEST_GROUP_PROBABILITY ? "test" : "control"
    setCookieWithExpiry(HEYFLOW_ABTEST_COOKIE_NAME, cookieValue, 14)
  }
  return cookieValue === "test"
}

/**
 * Retrieve the value of a specified cookie.
 *
 * @param {string} name - The name of the cookie.
 * @returns {string | undefined} - The value of the cookie, or undefined if not found.
 */
const getCookieValue = (name: string): string | undefined => {
  return document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${name}=`))
    ?.split("=")[1]
}

/**
 * Set a cookie with an optional expiry date.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} [days] - Number of days until the cookie expires.
 * @returns {void}
 */
export const setCookieWithExpiry = (name: string, value: string, days?: number) => {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value}${expires}; path=/`
}

/**
 * Provide a fallback value for the building floor count based on the floor count and residential type.
 *
 * NOTE: maybe will not be actually, after our feedback on this "guess" Project-A says they can add back
 * the building floors question in UI... but "meglio cauti che a fette coi crauti" ;-)
 *
 * @param {Floor} count - The floor count.
 * @param {string} [residential_type=DEFAULT_RESIDENTIAL_TYPE] - The residential type (default is "1").
 * @returns {Floor} - The determined building floor count.
 */
const guessBuildingFloorsWhenMissing = (count: Floor, residential_type: string = DEFAULT_RESIDENTIAL_TYPE): Floor => {
  // villa or independent house
  if (["2", "15"].includes(residential_type)) {
    return ["Basement", "Ground Floor", "Mezzanine"].includes(count) ? "1" : count
  }
  return ["6", "7", "8", "9", "10"].includes(count) ? "10" : "5"
}

/**
 * Parse a string as a number, returning undefined for invalid inputs.
 *
 * @param {string | undefined} value - The value to parse.
 * @returns {number | undefined} - The parsed number, or undefined if the input is invalid.
 */
const parseNumber = (value: string | undefined): number | undefined => {
  return value ? Number(value) : undefined
}

export type QueryStringFromHeyflow = {
  // address
  city: string
  country: string
  code_country: string
  street: string
  number: string
  zip_code: string
  province: string
  prov_initials: string
  latitude: string
  longitude: string
  // answers
  amenities?: string
  residential_type?: string
  employment?: string
  meters_size: string
  room_count: string
  bathroom_count: string
  floor_count: string
  building_floor_count?: string
  age: string
  heating_system?: string
  energetic_class?: string
  elevator?: string
  status?: string
  valuation_reason?: string
  process_status?: string
  not_sell?: string
  buy_and_sell?: string
  // contact
  name: string
  surname: string
  phone: string
  email: string
  third_party_allowed?: string
  mktg_allowed?: string
}

type Reasons = {
  reason: ValuationReason | undefined
  status: ValuationReasonSellingProcessStatus | undefined
  not_sell: ValuationReasonReasonNotToSell | undefined
  buy_and_sell: boolean | undefined
}

export const mapParamsToSurveyReason = (
  reason: ValuationReason | undefined,
  status: ValuationReasonSellingProcessStatus | undefined,
  notSellReason: ValuationReasonReasonNotToSell | undefined,
  buyAndSell: boolean | undefined
  // eslint-disable-next-line max-params
): Reasons => {
  if (!reason) {
    return getDefaultReasons()
  }

  switch (reason) {
    case "INTERESTED_IN_SELLING":
      return getInterestedInSellingReasons(status)

    case "ONLY_VALUATION":
      return getOnlyValuationReasons(notSellReason)

    case "BROKER":
      return { reason, status: undefined, not_sell: undefined, buy_and_sell: undefined }

    case "INTERESTED_IN_BUYING":
      return getInterestedInBuyingReasons(buyAndSell)

    default:
      return { reason, status, not_sell: notSellReason, buy_and_sell: buyAndSell }
  }
}

const getDefaultReasons = (): Reasons => ({
  reason: DEFAULT_VALUATION_REASON as ValuationReason,
  status: DEFAULT_VALUATION_REASON_SELLING_PROCESS_STATUS as ValuationReasonSellingProcessStatus,
  not_sell: undefined,
  buy_and_sell: undefined,
})

const getInterestedInSellingReasons = (status: ValuationReasonSellingProcessStatus | undefined): Reasons => ({
  reason: "INTERESTED_IN_SELLING" as ValuationReason,
  status: status || (DEFAULT_VALUATION_REASON_SELLING_PROCESS_STATUS as ValuationReasonSellingProcessStatus),
  not_sell: undefined,
  buy_and_sell: undefined,
})

const getOnlyValuationReasons = (notSellReason: ValuationReasonReasonNotToSell | undefined): Reasons => ({
  reason: "ONLY_VALUATION" as ValuationReason,
  status: undefined,
  not_sell: notSellReason || (DEFAULT_VALUATION_REASON_REASON_NOT_TO_SELL as ValuationReasonReasonNotToSell),
  buy_and_sell: undefined,
})

const getInterestedInBuyingReasons = (buyAndSell: boolean | undefined): Reasons => ({
  reason: "INTERESTED_IN_BUYING" as ValuationReason,
  status: undefined,
  not_sell: undefined,
  buy_and_sell: buyAndSell || false,
})

/**
 * Provides a fallback if unknown building floors and prevents odd values.
 *
 * In Heyflow floor and building_floors are not checked on UI, so customer may choose {floor: 9, building_floors: 3}.
 * This will break on adam side. In this case this method will use floor value for buildind_floors (9,9).
 *
 * @param buildingFloors
 * @param floor
 * @param residentialType
 */
export const handleBuildingFloorCount = (
  buildingFloors: string | undefined,
  floor: Floor,
  residentialType: ResidentialType
): string => {
  // exclude mezzanine/ground/basement
  const floorStrings = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

  // Check if the floor is a number
  if (floorStrings.includes(floor) && typeof buildingFloors === "string" && floorStrings.includes(buildingFloors)) {
    const parsedBuildingFloors = parseNumber(buildingFloors)
    const parsedFloor = parseNumber(floor)

    if (parsedBuildingFloors !== undefined && parsedFloor !== undefined && parsedBuildingFloors < parsedFloor) {
      buildingFloors = floor
    }
  }

  return buildingFloors || guessBuildingFloorsWhenMissing(floor, residentialType)
}

/**
 * Map query parameters from HeyFlow to the required answers, address, and contact objects.
 *
 * @param {QueryStringFromHeyflow} query - The query object containing parameters.
 * @returns {object} - The mapped answers, address, and contact objects.
 *
 * @throws HeyflowMappingError
 */
export const mapQueryStringFromHeyFlowToSurveyResponses = (
  query: QueryStringFromHeyflow
): { answers: Answers; address: Address; contact: Contact } => {
  const residentialType = (query.residential_type as ResidentialType) || DEFAULT_RESIDENTIAL_TYPE

  const amenities = query.amenities?.split(",")
  const hasBalcony = amenities?.includes("balcony")
  const hasTerrace = amenities?.includes("terrace")
  const hasGarden = amenities?.includes("garden")

  const surveyReason = mapParamsToSurveyReason(
    query.valuation_reason as ValuationReason,
    query.process_status as ValuationReasonSellingProcessStatus,
    query.not_sell as ValuationReasonReasonNotToSell,
    query.buy_and_sell ? query.buy_and_sell === "true" || undefined : undefined
  )

  const buildingFloorCount = handleBuildingFloorCount(
    query.building_floor_count,
    query.floor_count as Floor,
    residentialType
  )

  const answers: Answers = {
    "residential-type": residentialType,
    employment: (query.employment as StateAtDeed) || "Free",
    "meters-size": parseNumber(query.meters_size),
    "room-count": query.room_count,
    "bathroom-count": parseNumber(query.bathroom_count),
    "floor-count": query.floor_count as Floor,
    "building-floor-count": buildingFloorCount,
    age: Number(query.age),
    "heating-system": (query.heating_system as HeatingSystem) || "1", // 1 == NONE
    "energetic-class": (query.energetic_class as EnergyClass) || "Non lo so",
    elevator: (query.elevator as "0" | "1") || "0",
    garden: hasGarden ? 5 : null,
    terrace: hasTerrace ? 5 : null,
    balcony: hasBalcony ? "1" : null,
    status: (query.status as StateOfMaintenance) || "4", // 4 == NEED_RENOVATION
    valuationReason: surveyReason.reason,
    valuationReasonSellingProcessStatus: surveyReason.status,
    valuationReasonReasonNotToSell: surveyReason.not_sell,
    valuationReasonAlsoInterestedInSelling: surveyReason.buy_and_sell,
  }

  // Add a regular expression to check for valid Italian ZIP code format (5 digits)
  const isValidItalianZipCode = (zipCode: string): boolean => /^\d{5}$/.test(zipCode)

  if (!isValidItalianZipCode(query.zip_code)) {
    const error = new HeyflowMappingError(`heyflow_address_error`)

    Sentry.captureException(error, {
      extra: query,
      contexts: {
        user: {
          email: query.email,
        },
      },
      tags: {
        heyflow: true,
        heyflow_test: HEYFLOW_VALUATION_SOURCE,
      },
    })
    throw error
  }

  const address: Address = {
    city: query.city,
    country: query.country,
    countryCode: query.code_country,
    street: query.street,
    number: query.number,
    zipCode: query.zip_code,
    province: query.province,
    provinceInitials: query.prov_initials,
    coordinates: {
      latitude: parseFloat(query.latitude),
      longitude: parseFloat(query.longitude),
    },
  }

  const contact: Contact = {
    name: query.name,
    surname: query.surname,
    phone: query.phone,
    email: query.email,
    // TODO it seems heyflow doesn't provide a way set a variable for checkboxes, so by now default to false
    dataToBeSold: query.third_party_allowed === "true" || false,
    marketingAllowed: query.mktg_allowed === "true" || false,
  }
  return { answers, address, contact }
}
