export const waitFor = (isReady: () => boolean, { interval = 100 } = {}) => {
  return new Promise<void>((resolve) => {
    const id = setInterval(() => {
      if (isReady()) {
        clearInterval(id)
        resolve()
      }
    }, interval)
  })
}
