// Define a discriminated union type for geocode parameters
type GeocodeParams = { address: string } | { placeId: string }

function geocode(params: GeocodeParams): Promise<google.maps.GeocoderResult[]> {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode(params, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

export function geocodeByPlaceId(placeId: string): Promise<google.maps.GeocoderResult[]> {
  return geocode({ placeId })
}

export function geocodeByAddress(address: string): Promise<google.maps.GeocoderResult[]> {
  return geocode({ address })
}

export function getLatLng(result: google.maps.GeocoderResult): Promise<google.maps.LatLngLiteral> {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      }
      resolve(latLng)
    } catch (e) {
      reject(e)
    }
  })
}
