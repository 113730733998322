import { styled } from "../Theme"
/**
 * This is the main "container" of the app, it sets the max width to 768 and horizontal padding to 16.
 * It's supposed to be used to wrap everything that is not "touching the edges" (eg: FullWidthSection)
 */
export const ContentContainer = styled.div(({ theme }) => ({
  padding: `0px ${theme.spacings["sp-2"]}px`,
  width: "100%",
  maxWidth: 768,
}))
