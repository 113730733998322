import { geocodeByAddress } from "@app/components/AddressAutocomplete/utils"
import { Address } from "@app/types/address"
import * as Sentry from "@sentry/browser"

export const makeGetAddressComponent =
  (addressComponents: google.maps.GeocoderAddressComponent[]) => (component: string) =>
    addressComponents.find((address) => address.types.includes(component)) || { long_name: "", short_name: "" }

export const getAddressPayload = (geocodeResult: google.maps.GeocoderResult): Address => {
  const getAddressComponent = makeGetAddressComponent(geocodeResult.address_components)

  return {
    city: getAddressComponent("administrative_area_level_3").long_name || getAddressComponent("locality").long_name,
    country: getAddressComponent("country").long_name,
    countryCode: getAddressComponent("country").short_name,
    street: getAddressComponent("route").long_name,
    number: getAddressComponent("street_number").long_name,
    zipCode: getAddressComponent("postal_code").long_name,
    province: getAddressComponent("administrative_area_level_2").long_name || getAddressComponent("locality").long_name,
    provinceInitials:
      getAddressComponent("administrative_area_level_2").short_name || getAddressComponent("locality").short_name,
    coordinates: {
      latitude: geocodeResult.geometry.location.lat(),
      longitude: geocodeResult.geometry.location.lng(),
    },
  }
}

export const isAddressComplete = ({ city, country, street, number, zipCode }: Address) => {
  return Boolean(city && country && street && number && zipCode)
}

export const isAddressEmpty = ({
  city,
  country,
  street,
  number,
  zipCode,
}: Pick<Address, "city" | "country" | "street" | "number" | "zipCode">) => {
  return !city && !country && !street && !number && !zipCode
}

type SearchGeocodeResult = { isValidAddress: false } | { isValidAddress: true; address: Address }
export const searchGeocode = async (addressIn: Address): Promise<SearchGeocodeResult> => {
  const inputAddress = `${addressIn.street} ${addressIn.number}, ${addressIn.zipCode} ${addressIn.city} ${addressIn.country}`

  try {
    const results = await geocodeByAddress(inputAddress)

    const address = getAddressPayload(results[0])

    if (!address.number || address.number.length == 0) {
      return { isValidAddress: false }
    }

    return {
      isValidAddress: true,
      address,
    }
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        address: addressIn,
      },
    })
    return {
      isValidAddress: false,
    }
  }
}
