import { useRouter } from "next/router"
import { useEffect } from "react"

export const REFERRAL_DATA = "referral"

const queryParamsToReferralDataMap = {
  referral_id: "referral_id",
  user_email: "user_email",
  utm_source: "utm_source",
  utm_medium: "utm_medium",
} as const
type ParamsKeys = keyof typeof queryParamsToReferralDataMap
type StoreKeys = (typeof queryParamsToReferralDataMap)[ParamsKeys]
type ReferralData = Record<StoreKeys, string>

export const useSaveReferralParams = () => {
  const router = useRouter()
  useEffect(() => {
    const referralData = {} as ReferralData
    for (const queryParam in queryParamsToReferralDataMap) {
      const queryParamValue = router.query[queryParam]
      if (queryParamValue !== undefined) {
        referralData[queryParamsToReferralDataMap[queryParam as ParamsKeys]] = queryParamValue as string
      }
    }

    window.sessionStorage.setItem(REFERRAL_DATA, JSON.stringify(referralData))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const getReferralParams = (): ReferralData | undefined => {
  const referralData = window.sessionStorage.getItem(REFERRAL_DATA)
  return referralData ? JSON.parse(referralData) : undefined
}
