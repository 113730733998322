import { Fragment, ReactNode } from "react"

import LoaderIcon from "../LoaderIcon"

type Props = {
  isLoading: boolean
  children?: ReactNode
}

export const Loader: React.FC<Props> = (props) => {
  if (props.isLoading) {
    return (
      <div
        css={{ alignItems: "center", display: "flex", height: "100%", justifyContent: "center" }}
        data-testid="loader-component"
      >
        <LoaderIcon />
      </div>
    )
  }

  return <Fragment>{props.children}</Fragment>
}
