import { isCasavo } from "@app/lib/tenants/isCasavo"
import { keyframes, useTheme } from "@emotion/react"

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

type Variant = "dark" | "light"

interface Props {
  variant?: Variant
}

const LoaderIcon: React.FC<Props> = ({ variant = "dark" }) => {
  const theme = useTheme()
  const isCasavoTenant = isCasavo()
  const colorsMap: Record<Variant, [string, string]> = {
    dark: [theme.colors.brandedLoaderColor1, theme.colors.brandedLoaderColor2],
    light: [`${theme.colors.grey0}95`, theme.colors.grey0],
  }

  return (
    <svg
      className="circular"
      css={{
        animation: `${rotate} 2s linear infinite`,
      }}
      height="40"
      viewBox="0 0 50 50"
      width="40"
    >
      <circle
        css={(theme) => ({
          stroke: colorsMap[variant][0],
          strokeWidth: 2,
        })}
        cx="25"
        cy="25"
        fill="none"
        r="20"
        strokeMiterlimit="10"
        strokeWidth="3"
      />

      <circle
        css={(theme) => ({
          animation: `${dash} 2s ease-in-out infinite`,
          stroke: colorsMap[variant][1],
          strokeLinecap: "round",
          strokeWidth: 3,
        })}
        cx="25"
        cy="25"
        fill="none"
        r="20"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </svg>
  )
}

export default LoaderIcon
